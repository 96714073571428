<template>
  <div class="box-content">
    <mcp-modal :show="show" @closeModal="closeModal">
      <avatar @closeModal="closeModal" />
    </mcp-modal>
    <ul class="tab-heading">
      <li
        @click.prevent="activeTab(key)"
        :class="key === index ? 'active-head' : ''"
        v-for="(header, key) in headers"
        :key="key"
      >
        <a href="#">{{ header }}</a>
      </li>
    </ul>
    <div v-if="index === 0">
      <div class="t-heading pt-30">Informations</div>
      <div class="section__p">
        <div class="x-flex">
          <img :src="user.photo" class="img__mcp" alt="" />
          <label style="cursor: pointer" @click="show = true">Modifier</label>
        </div>
        <div class="pt-10">
          <div class="i-section">
            <div class="i-info">
              <text-input
                label="Nom"
                v-model="form.lastname"
                :errors="errors.lastname"
              />
              <div class="pt-10">
                <text-input
                  label="Prénom"
                  v-model="form.firstname"
                  :errors="errors.firstname"
                />
              </div>
            </div>
            <div class="i-info">
              <text-input
                label="Email"
                v-model="form.email"
                :errors="errors.email"
              />
              <div class="pt-10">
                <text-input
                  label="Téléphone"
                  v-model="form.phone"
                  :errors="errors.phone"
                />
              </div>
            </div>
          </div>
          <div class="pt-10 d-flex-right">
            <button
              @click="updateProfile"
              :disabled="loading"
              :class="
                loading ? 'button is-loading is-primary' : 'button is-primary'
              "
            >
              Enregistrer
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="pt-10" v-if="index === 1">
      <span class="t-heading">Changer votre mot de passe</span>
      <div class="section_pp">
        <text-input
          type="password"
          v-model="form_pass.old_password"
          :errors="errors.old_password"
          label="Ancien mot de passe"
        />
        <text-input
          type="password"
          v-model="form_pass.password"
          :errors="errors.password"
          label="Nouveau mot de passe"
        />
        <button
          :disabled="loading"
          @click="updatePassword"
          :class="
            loading
              ? 'button is-loading is-primary mt-19'
              : 'button is-primary mt-19'
          "
        >
          Enregistrer
        </button>
      </div>
    </div>

    <div class="pt-10" v-if="index === 2">
      <span class="t-heading">Générer une nouvelle clé API</span>
      <div class="section_ppp">
        <text-input
          id="APIkey"
          v-model="user.apikey"
          label="API key"
          readonly
        />
        <button @click="copyContent" class="button mt-19">Copier</button>
        <button
          @click="generateApiKey"
          :disabled="loading || info.v === 0"
          :class="
            loading
              ? 'button is-loading is-primary mt-19'
              : 'button is-primary mt-19'
          "
        >
          Générer
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { getAuth, authenticate } from "@/utils/Auth";
import { EventBus } from "@/utils/EventBus";
import TextInput from "@/components/TextInput";
import { TYPES } from "@/store/types";
import McpModal from "@/components/McpModal";
import avatar from "./avatar";

export default {
  name: "profile",
  components: {
    TextInput,
    McpModal,
    avatar,
  },
  data: () => ({
    headers: ["Informations generals", "Mot de passe"],
    user: {},
    index: 0,
    loading: false,
    errors: [],
    form_pass: {
      old_password: "",
      password: "",
    },
    form: {
      firstname: "",
      lastname: "",
      phone: "",
      email: "",
      mcp_id: "",
    },
    show: false,
    info: { p: 0, v: 0 },
  }),

  mounted() {
    this.user = getAuth();
    if (this.user.profile === 0) {
      this.headers.push("Clé API");
      EventBus.$on("auth", this.currentAuth);
    }
    this.form.firstname = this.user.firstname;
    this.form.lastname = this.user.lastname;
    this.form.phone = this.user.phone;
    this.form.email = this.user.email;
    this.form.mcp_id = this.user.mcp_id;
  },
  methods: {
    currentAuth(user) {
      this.info = user;
    },

    closeModal() {
      this.show = !this.show;
    },

    copyContent() {
      var copyText = document.getElementById("APIkey");
      copyText.select();
      copyText.setSelectionRange(0, 99999);
      navigator.clipboard.writeText(copyText.value);
    },

    activeTab(key) {
      if (this.index != key) {
        this.index = key;
        this.errors = [];
      }
    },

    updatePassword() {
      this.loading = true;
      this.$store
        .dispatch(
          `${TYPES.modules.UTILS + "/" + TYPES.actions.UPDATE_PASSWORD}`,
          this.form_pass
        )
        .then((res) => {
          this.loading = false;
          this.errors = [];
          this.form_pass.password = "";
          this.form_pass.old_password = "";
          this.$toast.success(`Opération éffectuée`);
        })
        .catch((error) => {
          this.loading = false;
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
          }
        });
    },

    generateApiKey() {
      this.loading = true;
      this.$store
        .dispatch(
          `${TYPES.modules.UTILS + "/" + TYPES.actions.GENERATE_API_KEY}`
        )
        .then((res) => {
          if (res.status === true) {
            this.loading = false;
            this.user.apikey = res.apikey;
            authenticate(this.user);
            this.errors = [];
            this.$toast.success(`Opération éffectuée`);
          } else {
            this.loading = false;
            this.$toast.error(`Impossible de mettre à jour l'api key`);
          }
        })
        .catch((error) => {
          this.loading = false;
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
          }
        });
    },

    updateProfile() {
      this.loading = true;
      this.$store
        .dispatch(
          `${TYPES.modules.UTILS + "/" + TYPES.actions.UPDATE_PROFILE}`,
          this.form
        )
        .then((res) => {
          if (res.status === true) {
            this.user.firstname = this.form.firstname;
            this.user.lastname = this.form.lastname;
            this.user.phone = this.form.phone;
            this.user.email = this.form.email;
            authenticate(this.user);
            EventBus.$emit("updateProfile", this.user);
            this.errors = [];
            this.$toast.success(`Opération éffectuée`);
            this.loading = false;
          } else {
            this.loading = false;
            this.$toast.error(`Impossible de mettre à jour les informations`);
          }
        })
        .catch((error) => {
          this.loading = false;
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.section__p {
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 20px;
}
.img__mcp {
  border-radius: 50%;
  width: 50%;
}
.x-flex {
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}
.i-section {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
}
.section_pp {
  padding-top: 30px;
  display: grid;
  grid-template-columns: 220px 220px 100px;
  grid-gap: 10px;
}
.section_ppp {
  padding-top: 30px;
  display: grid;
  grid-template-columns: 250px 100px 100px;
  grid-gap: 10px;
}
.t-heading {
  font-size: 15px;
}
</style>