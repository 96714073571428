<template>
  <div>
    <h2>Modifier la photo de profile</h2>
    <div class="pt-10">
      <input
        ref="file"
        :class="errors.image ? 'is-danger' : ''"
        v-on:change="handleFileUpload()"
        type="file"
        class="input"
      />
      <div v-if="errors.image" :class="errors.image ? 'invalid-feedback' : ''">
        <strong class="has-text-danger" style="font-size: 12px">{{
          errors.image[0]
        }}</strong>
      </div>
    </div>
    <div class="pt-10 d-flex-right">
      <button
        @click="updatePhoto"
        :disabled="loading"
        :class="
          loading
            ? 'button is-responsive is-loading is-primary'
            : 'button is-responsive is-primary'
        "
      >
        Enregistrer
      </button>
      <button class="button is-outlined is-responsive" @click="cancelOperation">
        Annuler
      </button>
    </div>
  </div>
</template>

<script>
import { TYPES } from "@/store/types";
import { EventBus } from "@/utils/EventBus";
export default {
  name: "avatar",
  data: () => ({
    loading: false,
    file: "",
    errors: [],
  }),
  methods: {
    updatePhoto() {
      this.loading = true;
      let form = new FormData();
      form.append("image", this.file);
      this.$store
        .dispatch(
          `${TYPES.modules.UTILS + "/" + TYPES.actions.UPDATE_AVATAR}`,
          form
        )
        .then((res) => {
          this.loading = false;
          if (res.status === true) {
            EventBus.$emit("avatar", res.avatar);
            this.$toast.success(`Opération effectuée`);
          }
          this.$emit("closeModal");
        })
        .catch((error) => {
          this.loading = false;
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
          }
        });
    },
    cancelOperation() {
      this.$emit("closeModal");
    },
    handleFileUpload() {
      this.file = this.$refs.file.files[0];
    },
  },
};
</script>